<template>
  <div class="jiceng">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    if (this.isMobile()) {
      document.title = "2023基层透析发展论坛";
    } else {
      // this.$message("请在手机上打开");
    }
  },
  methods: {
    isMobile() {
      let userAgentInfo = navigator.userAgent;
      let Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      let getArr = Agents.filter((i) => userAgentInfo.includes(i));
      return getArr.length ? true : false;
    },
  },
};
</script>
<style scoped lang="scss">
.jiceng{
  @media (min-width: 480px) {
    width: 480px;
    margin: 0 auto;
    background-color: #fff;
  }
}
</style>
